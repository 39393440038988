<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/other' }">其他设置</el-breadcrumb-item>
      <el-breadcrumb-item>系统广播</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table" style="width: 50%; margin-top: 10px">
        <el-form label-width="100px">
          <el-form-item label="广播内容" prop="title">
            <el-input type="textarea" v-model="title" ></el-input>
            <p>\n表示换行，&amp;nbsp;表示空格</p>
          </el-form-item>
          
          <!-- 操作 -->
          <el-form-item>
            <el-button type="primary" class="btnAdd" @click="save()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

  </div>
</template>

<script>
export default {
  data () {
    return {
      title: '',
      id: '',
    } 
  },
  created () {
    
    // 商品列表
    this.loadData()
  },
  methods: {
    //  获取商品列表信息
    loadData () {
      
      this.$api.QueryRadio().then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        } 
        this.title = res.data[0].title;
        this.id = res.data[0].id;
      })
    },
    
    // 添加商品
    save () {
      
      this.$api.UpdateRadio({id: this.id, title: this.title}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('保存失败')
        } 
        this.$message.success('保存成功')
      })
      
      
    },

  },
}
</script>

<style lang="scss" scoped>
  .el-select{
    width:100%;
    margin-bottom: 10px;
  }
  .add-btn{
    width: 100%;
    border: 1px dashed #ccc;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .select-row{
    position: relative;

  }
  .close-btn{
    position: absolute;
    right: -20px;
    top: 14px;
    cursor: pointer
  }
</style>
